@import './_variables'

@keyframes spotlight-pulse-animation
  0%
    background-color: rgba(255, 255, 255, 0.8)
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.8)
  15%
    background-color: rgba(255, 255, 255, 0.6)
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.8)
  50%
    background-color: rgba(255, 255, 255, 0)
    box-shadow: 0 0 5px 20px rgba(255, 255, 255, 0)

#spotlight
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: $restaurant-header-height
  height: $restaurant-header-height
  margin-top: 6px
  top: 0px
  right: 0px
  opacity: 0
  transition: opacity 0.2s ease-in
  &.spotlight-active
    opacity: 1

  .spotlight-button
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative
    width: $spacing-xl
    height: $spacing-xl
    border-radius: $spacing-xl / 2
    margin-top: -$spacing-s
    &.spotlight-pulse
      animation: spotlight-pulse-animation 2s infinite
    .message-bubble-icon, .card-icon, .video-icon
      width: $spacing-xl
      overflow: visible
      filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.4))
    .spotlight-button-title
      position: absolute
      width: $restaurant-header-height - 2 * $spacing-s
      top: $spacing-xl + $spacing-s
      left: calc(50% - $restaurant-header-height / 2 + $spacing-s)
      text-align: center
      font-size: $font-size-xs
      background-color: rgba(255, 255, 255, 0.9)
      border-radius: $spacing-s
      padding: 0px $spacing-xs
      color: #000000
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))
  



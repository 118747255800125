@import './_variables'
$nps-colors: ('nps-color-0', #d61c16), ('nps-color-1', #e24a18), ('nps-color-2', #e9661a), ('nps-color-3', #ef801a), ('nps-color-4', #f49d1a), ('nps-color-5', #efb819), ('nps-color-6', #edc906), ('nps-color-7', #d0ce1f), ('nps-color-8', #b8d339), ('nps-color-9', #94d556), ('nps-color-10', #77d167) 

@keyframes message-in
  0%
    opacity: 0
    transform: translateY($spacing-l)
    max-height: 0px
    margin-top: -$spacing-m

  80%
    opacity: 1
  
  100%
    transform: translateY(0px)
    max-height: 100dvh
    margin-top: 0px

@keyframes option-selected-in
  0%
    transform: scale(1.025)
  
  100%
    transform: scale(1)

@keyframes tag-option-selected-in
  0%
    transform: scale(1.04)
  
  100%
    transform: scale(1)

@keyframes nps-option-selected-in
  0%
    transform: scale(1.4)
  
  100%
    transform: scale(1.2)  

.poll-message
  display: flex
  flex-direction: column
  gap: $spacing-m
  background-color: #ffffff
  padding: $spacing-s + $spacing-xs $spacing-m + $spacing-s
  border-radius: $spacing-m + $spacing-s
  overflow: hidden
  animation: .4s ease-in-out forwards message-in

  &.left
    margin-right: $spacing-xl
  &.right
    margin-left: $spacing-xl
    padding: $spacing-m


  .question-title
    font-size: $font-size-l
    font-weight: 600
    line-height: $font-size-l + 4px
    text-align: center
  .options-list
    display: flex
    flex-direction: column
    gap: $spacing-m
    .option
      border: solid 1px #e24a18
      background-color: #ffffff
      color: #e24a18
      text-align: center
      font-size: $font-size-l
      font-weight: 500
      padding: $spacing-s $spacing-m
      border-radius: $spacing-s
      &.selected
        background-color: #e24a18
        color: #ffffff
        animation: .15s ease-in-out forwards option-selected-in
  .tag-options-list
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: $spacing-s
    .option
      border: solid 1px #ef801a
      background-color: #ffffff
      color: #ef801a
      text-align: center
      font-size: $font-size-m
      font-weight: 500
      padding: 3px $spacing-s * 2 4px $spacing-s * 2
      border-radius: $spacing-m
      &.selected
        background-color: #ef801a
        color: #ffffff
        animation: .15s ease-in-out forwards tag-option-selected-in  

  .nps-interface
    .nps-scale-caption
      display: flex
      flex-direction: row
      justify-content: space-between
      font-size: $font-size-xs
      color: $color-muted-grey
      margin-bottom: $spacing-xs
    .nps-options-list
      display: flex
      flex-direction: row
      gap: $spacing-xs
      margin-bottom: $spacing-xs
      .nps-option
        flex: 1 1 0px
        background-color: #d21f3c
        color: #ffffff
        text-align: center
        font-size: $font-size-l
        font-weight: 500
        padding: $spacing-s 0
        border-radius: $spacing-xs
        @each $color in $nps-colors
          &.#{nth($color,1)}
            background-color: nth($color,2)
        &.selected
          animation: .2s ease-in-out forwards nps-option-selected-in

  .complete-button
    background-color: #666666
    color: #ffffff
    text-align: center
    font-size: $font-size-l
    font-weight: 500
    padding: $spacing-s $spacing-m
    border-radius: $spacing-s